<template>
  <Drawer :is-open="isHamburgerMenuOpen" @close="toggleHamburgerMenu(false)">
    <div class="flex flex-col gap-16">
      <ul
        v-for="menu in headerNavigation"
        :key="menu._uid"
        class="col-span-3 flex flex-col gap-6"
      >
        <li v-for="menuItem in menu?.menuItems" :key="menuItem._uid">
          <StoryblokComponent
            :blok="menuItem"
            :hide-icon="true"
            class="flex w-full items-center gap-[2px] text-lg font-bold text-gray-700"
          />
        </li>
      </ul>

      <div
        v-for="subMenu in subMenus"
        :key="subMenu._uid"
        class="flex flex-col gap-3"
      >
        <h3
          v-if="subMenu.smallScreenTitle"
          class="border-b-2 border-gray-300 pb-2 text-sm font-medium text-gray-700"
        >
          {{ subMenu.smallScreenTitle }}
        </h3>

        <ul class="flex flex-col gap-4">
          <li v-for="menuItem in subMenu.menuItems" :key="menuItem._uid">
            <StoryblokComponent
              :blok="menuItem"
              class="text-sm text-gray-900"
            />
          </li>
        </ul>
      </div>
    </div>
  </Drawer>
</template>

<script setup lang="ts">
import type { BlockNavigationMenuStoryblok } from '@/types/storyblok'

const { headerNavigation } = await useConfigStore().asyncConfig()
const uiStore = useUIStore()
const { toggleHamburgerMenu } = uiStore
const { isHamburgerMenuOpen } = storeToRefs(uiStore)
const subMenus = computed<BlockNavigationMenuStoryblok[]>(() => {
  return (
    headerNavigation.value?.flatMap(
      (menu) =>
        menu.menuItems?.flatMap(
          (menuItem) =>
            menuItem.childMenu
              ?.filter((childMenu) => childMenu.showOnSmallScreens)
              .flatMap((childMenu) => childMenu.menuItems || []) || [],
        ) || [],
    ) || []
  )
})
</script>
