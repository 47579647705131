<template>
  <div
    :class="{
      'lg:hidden': hiddenOnDesktop,
    }"
  >
    <Transition name="drawer-overlay">
      <div
        v-if="isOpen"
        key="overlay"
        class="ease-out-expo fixed left-0 top-0 z-40 size-full bg-black/40"
        @click="closeDrawer"
      />
    </Transition>

    <Transition name="drawer" tag="div">
      <div v-if="isOpen" class="c-drawer fixed bottom-0 left-0 z-40 w-full">
        <div
          v-if="isOpen"
          class="c-drawer__inner ease-out-expo container relative z-50 h-full transform overflow-auto rounded-t-xl bg-white pb-32 pt-5 transition-transform duration-700"
        >
          <div class="flex flex-col gap-4">
            <button
              class="sticky top-0 ml-auto text-gray-300"
              @click="closeDrawer"
            >
              <span class="sr-only">Close Drawer</span>
              <UiIcon icon="close" size="md" />
            </button>

            <slot />
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script setup lang="ts">
withDefaults(
  defineProps<{
    isOpen: boolean
    hiddenOnDesktop?: boolean
  }>(),
  {
    hiddenOnDesktop: true,
  },
)

const emit = defineEmits<{
  close: []
}>()

onMounted(() => {
  window.addEventListener('keyup', (e) => {
    if (e.code === 'Escape') {
      closeDrawer()
    }
  })
})

function closeDrawer(): void {
  emit('close')
}
</script>

<style scoped lang="postcss">
.c-drawer {
  height: calc(
    theme('height.dvh') - var(--globalHeaderHeightMobile) - theme('spacing.5')
  );
}

.c-drawer__inner {
  background-color: var(--custom-fill-color, theme('colors.white'));
}

.drawer-overlay-enter-active,
.drawer-overlay-leave-active,
.drawer-enter-active,
.drawer-leave-active {
  @apply ease-out-expo transition-all duration-700;
}

.drawer-overlay-enter-from,
.drawer-overlay-leave-to {
  @apply opacity-0;
}

.drawer-enter-from {
  @apply translate-y-full transform delay-1000;
}

.drawer-leave-to {
  @apply translate-y-full transform;
}
</style>
